.dashboard-container {
   padding: 10px 50px;
}

.header-for-dashboard {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.header-for-dashboard img {
   max-height: 30px;
}
.header-for-dashboard2 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 20px 10px;
}

.header-for-dashboard2 img {
   max-height: 30px;
}

.icons-wrapper span{
   margin-left: 10px;
   cursor: pointer;
}

.tr-row {
   background-color: #011460;
   color: #fff;
   cursor: pointer;
}

.tr-row-active, .tr-row:hover {
   background-color: #fff;
   color: #011460;
}

.w-100{
   width: 100%;
}

.ant-card-head {
   border-bottom: none !important;
}
.card{
   margin: 0 auto;
   padding: 20px;
   background: white;
   text-align: center;
   border-radius: 10px;
   position: absolute;
   display: flex;
   flex-direction: column;
   align-items: center;
   top: 14%;
   left: 37%;
border: 1px solid white;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; }

.custom-placeholder{
   padding: 10px;
   width: 20em;
   font-size: medium;
   border-radius: 10px;
}
.custom-green-button {
   width: 18em;
   padding: 20px;
   background-color: green;
   border-color: green;
   color: white;
   transition: none !important;
   margin-left: -106px;
   font-size: large;
    font-weight: bolder; /* Disable hover transition */
 }
 
 .custom-green-button:hover {
   background-color: green; /* Prevent background color change on hover */
   border-color: green; /* Prevent border color change on hover */
   color: white; /* Prevent text color change on hover */
 }
 .ant-card-body {
   padding: 0px !important;
}
 
.custom-placeholder .ant-select-selector {
   padding: 10px; /* Adjust the padding */
   width: 20em; /* Adjust the width */
   font-size: medium; /* Set font size */
   border-radius: 10px; /* Set border radius */
 }
 
 .custom-placeholder .ant-select-selection-placeholder {
   color: rgb(187, 58, 58); /* Placeholder text color */
   font-style: italic; /* Italic style for placeholder */
   padding: 10px; /* Adjust the padding */
   width: 20em; /* Adjust the width */
   font-size: medium; /* Set font size */
   border-radius: 10px; 
 }
